import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { mean, sumBy } from 'lodash'
import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Space,
} from 'antd'
import {
  updateShippingContainer,
  deleteShippingContainerPackage,
  getShippingContainerPackages,
} from '../api'
import { normalizeValue } from '../../../pages/scan/Scan'
import { NavigateButton, Package, updateDraftCounters } from '../../../components'
import { resetForm } from './Finish'

import { AddProductModal, OneTimeConfirmModal } from './modals'

import { currentScanDetails } from '../../../Layout'

dayjs.extend(utc)

let keyDownTime = null

const BarcodePage = ({ barcodes }) => {
  const { t } = useTranslation()

  const barcodeTagRef = useRef(null)

  const [barCodeform] = Form.useForm()

  const [timeHandler, setTimeHandler] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [fieldError, setFieldError] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isConfirmModalVisible, setConfirmModalisibility] = useState(false)
  const [packages, setPackages] = useState([])

  useEffect(() => {
    if (currentScanDetails.value.data.value[2].id) {
      fetchPackages()
    }
  }, [barcodes])

  const fetchPackages = () => {
    currentScanDetails.value.stepIsLoading.value = true

    getShippingContainerPackages(currentScanDetails.value.data.value[2].id)
      .then(data => {
        setPackages(data)
      })
      .catch(error => {
        message.open({
          content: error.message,
          type: 'error',
        })
      })
      .finally(() => {
        currentScanDetails.value.stepIsLoading.value = false
      })
  }

  const getPackageDetails = useCallback((sku, target) => ({
    ...barcodes[currentScanDetails.value.data.value[0].country][sku],
    upc: sku,
  }), [barcodes])

  const checkBarcode = useCallback((value) => {
    const currentBarcode = barcodeTagRef.current.input.value

    setErrorMessage(null)
    setFieldError(false)

    if (!currentBarcode || currentBarcode.includes('::loop::')) {
      barCodeform.resetFields()
      return false
    }

    if (!barcodes[currentScanDetails.value.data.value[0].country][currentBarcode]) {
      setErrorMessage(t('step4ErrorBarcode'))
      setFieldError(true)

      barCodeform.resetFields()

      message.open({
        content: t('step4ErrorBarcode'),
        type: 'error',
      })
    } else {
      setTimeHandler([])
      const packageProduct = getPackageDetails(currentBarcode)

      setSelectedProduct(packageProduct)
      setModalVisibility(true)
    }
  }, [barCodeform, barcodes, getPackageDetails, t])

  const editPackage = (selectedPackage) => {
    const packageProduct = getPackageDetails(selectedPackage.upc)

    setSelectedPackage(selectedPackage)
    setSelectedProduct(packageProduct)

    if (packageProduct) {
      setModalVisibility(true)
    }
  }

  const deletePackage = (packageId) => {
    currentScanDetails.value.stepIsLoading.value = true

    deleteShippingContainerPackage(currentScanDetails.value.data.value[2].id, packageId)
      .then(data => {
        fetchPackages()
      })
      .catch(error => {
        message.open({
          content: error.message,
          type: 'error',
        })
      })
      .finally(() => {
        currentScanDetails.value.stepIsLoading.value = false
      })
  }

  const addProductOnCancel = () => {
    setSelectedPackage(null)
    setSelectedProduct(null)

    setModalVisibility(false)

    barCodeform.resetFields()

    barcodeTagRef.current.focus()
  }

  const addProductOnSave = () => {
    fetchPackages()

    addProductOnCancel()
  }

  const onKeyDown = (event) => keyDownTime = new Date().getTime()

  const onPaste = (event) => setTimeHandler(prevState => [100, 100, 100])

  const onKeyUp = (event) => {
    if (event.target.value.length <= 1) setTimeHandler([])

    const keyUpTime = new Date().getTime()
    const currentKeyMs = keyUpTime - keyDownTime

    setTimeHandler(prevState => [...prevState, currentKeyMs])
  }

  const onFinishCheck = () => {
    const packageQty = sumBy(packages, 'quantity')

    if (currentScanDetails.value.data.value[2].coms_container?.type?.type === 'one_time' && packageQty > 1) {
      setConfirmModalisibility(true)
    } else {
      onFinish()
    }
  }

  const onBreakClick = () => {
    resetForm()
    updateDraftCounters()

    message.open({
      content: t('breakInfoText'),
      duration: 5,
      type: 'warning',
    })
  }

  const goBackToEdit = () => {
    setConfirmModalisibility(false)
  }

  const onFinish = () => {
    barcodeTagRef.current.blur()

    const currentTime = dayjs.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    const fields = {
      arrived_at: currentTime,
      comment: currentScanDetails.value.data.value[2].comment,
      condition: currentScanDetails.value.data.value[2].condition,
      identity_code:  currentScanDetails.value.data.value[2].identity_code,
      partner_code: currentScanDetails.value.data.value[0].code,
      processed_at: currentTime,
      sent_back_at: currentTime,
      store_id: currentScanDetails.value.data.value[1].store_id,
    }

    currentScanDetails.value.stepIsLoading.value = true

    updateShippingContainer(currentScanDetails.value.data.value[2].id, fields)
      .then((data) => {
        message.open({
          content: t('shippingContainerSuccessfullySaved'),
          type: 'success',
        })

        currentScanDetails.value.stepIsLoading.value = false
        currentScanDetails.value.step.value = 4
      })
      .catch((error) => {
        console.log(error.message)
        message.open({
          content: error.message,
          type: 'error',
        })

        barCodeform.resetFields()

        currentScanDetails.value.stepIsLoading.value = false
      })
  }

  const action = currentScanDetails.value.barCodeAction.value

  if (action && !isConfirmModalVisible && !isModalVisible) {
    switch (action) {
    case '::loop::goBacktoEdit':
      setTimeout(goBackToEdit, 100)
      break
    case '::loop::checkFinish':
      setTimeout(onFinishCheck, 100)
      break
    case '::loop::finish':
      setTimeout(onFinish, 100)
      break
    default:
      if (action.includes('::loop::editPackage')) {
        const id = parseInt(action.split('-')[1], 10)
        setTimeout(() => editPackage(id), 100)
      }
    }

    currentScanDetails.value.barCodeAction.value = null
  }

  const avgMs = mean(timeHandler) || 0

  return (
    <>
      <Row>
        <Col span={16}>
          {timeHandler.length > 2 && avgMs > 30 &&
            <Alert message={t('manualInputWarning')} type="warning" />
          }

          <Form
            form={barCodeform}
            initialValues={{
              barcode: '',
            }}
            layout="vertical"
            size="large"
          >
            <Form.Item
              help={errorMessage}
              label={t('step4ScanBarcode')}
              name="barcode"
              normalize={normalizeValue}
              validateStatus={fieldError && 'error'}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  autoFocus
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onPaste={onPaste}
                  onPressEnter={checkBarcode}
                  ref={barcodeTagRef}
                  size="large"
                />
                <Button onClick={checkBarcode} type="primary">{t('addBarcode')}</Button>
              </Space.Compact>

            </Form.Item>
          </Form>
        </Col>

        <Col span={8}>
          <div className="button-container">
            <NavigateButton
              disabled={currentScanDetails.value.stepIsLoading.value}
              label={t('finish')}
              loading={currentScanDetails.value.stepIsLoading.value}
              onClick={() => onFinishCheck()}
              qrAction="::loop::checkFinish"
            />

            <Button className="break-button" danger onClick={onBreakClick} size="large" type="primary">{t('break')}</Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Flex justify="space-between" style={{ width: '100%' }}>
          <Col>
            <h2>{t('packages')}</h2>
          </Col>

        </Flex>
      </Row>
      <Row>
        <Col span={16}>
          {React.Children.toArray(
            packages.map((pack) => (
              <Package
                deletePackage={() => deletePackage(pack.id)}
                editPackage={() => editPackage(pack)}
                pack={{
                  ...pack,
                  product: getPackageDetails(pack.upc),
                }}
              />
            )))}
        </Col>
      </Row>

      {isConfirmModalVisible &&
         <OneTimeConfirmModal
           isVisible={isConfirmModalVisible}
           onCancelFn={goBackToEdit}
           onOkFn={onFinish}
         />
      }

      {isModalVisible &&
        <AddProductModal
          isScan={avgMs > 30}
          isVisible={isModalVisible}
          onCancelCallback={addProductOnCancel}
          onSaveCallback={addProductOnSave}
          product={selectedProduct}
          selectedPackage={selectedPackage}
        />
      }
    </>
  )
}

BarcodePage.propTypes = {
  barcodes: PropTypes.object,
}

export default BarcodePage
