import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Badge, Button, Empty, message, Modal, Table } from 'antd'
import { signal } from '@preact/signals-react'
import { useTranslation } from 'react-i18next'

import { getAllShippingContainer } from './api'

import { verifyQrCode } from '../../pages/scan/api'
import { loadShippingContainer } from '../../pages/scan/Scan'

import { currentScanDetails } from '../../Layout'

dayjs.extend(utc)

const draftCounter = signal()

export const updateDraftCounters = () => {
  const partner_code = currentScanDetails.value.data.value[0].code || localStorage.getItem('checkin_step1_partnerCode')

  if (partner_code) {
    getAllShippingContainer(`?data_processing[]=raw_data&partner_code=${partner_code}`)
      .then(({ shipping_containers }) => {
        draftCounter.value = shipping_containers
      })
      .catch((error) => {
        console.log(error.message)
      })
  }
}

const DraftCounter = () => {
  const { t } = useTranslation()
  const partner_code = currentScanDetails.value.data.value[0].code || localStorage.getItem('checkin_step1_partnerCode')

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    updateDraftCounters()
  }, [])

  const getShippingDetails = (identity_code, partner_code) => {
    message.destroy()

    currentScanDetails.value.stepIsLoading.value = true
    currentScanDetails.value.step.value = 0

    verifyQrCode({ identity_code, partner_code })
      .then((data) => {
        loadShippingContainer(data.id, data.coms_container)

        message.open({
          content:  t('loadedFromReturn'),
          duration: 5,
          type: 'info',
        })

        setIsModalOpen(false)
      })
      .catch((error) => {
        message.open({
          content: error.message || t('step2ErrorQrCode'),
          type: 'error',
        })

        currentScanDetails.value.stepIsLoading.value = false
      })
  }

  if (!partner_code || !draftCounter.value) return null

  let color = 'green'

  if (draftCounter.value.length > 0 && draftCounter.value.length <= 3) color = 'yellow'
  if (draftCounter.value.length > 3 && draftCounter.value.length <= 5) color = 'orange'
  if (draftCounter.value.length > 5) color = 'red'

  const columns = [
    {
      dataIndex: 'identity_code',
      key: 'identity_code',
      title: t('qrCode'),
    },
    {
      dataIndex: 'quantity_of_packages',
      key: 'quantity_of_packages',
      title: t('itemCountTable'),
    },
    {
      key: 'arrived_at',
      render: (_, record) => dayjs.utc(record.arrived_at).format('YYYY-MM-DD HH:mm:ss'),
      title: t('startedAt'),
    },
    {
      key: 'actions',
      render: (_, record) => <Button disabled={currentScanDetails.value.stepIsLoading.value} onClick={() => getShippingDetails(record.identity_code, record.partner_code)} type="primary">{t('continue')}</Button>,
      title: t('actions'),
      width: '10%',
    },
  ]

  return (
    <>
      <Badge.Ribbon
        className="draft-counter"
        color={color}
        text={
          <Button href="#" onClick={() => setIsModalOpen(true)} type="link">
            <span style={{ color: 'white' }}>{t('openReturns')}:</span> <Badge color={color} count={draftCounter.value.length} showZero style={{ boxShadow: 'none' }} />
          </Button>
        }
      />

      <Modal
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        title={t('openReturns')}
        width="60%"
      >
        <Table
          bordered
          className="shippingContainerTableSelenium"
          columns={columns}
          dataSource={draftCounter.value}
          locale={{ emptyText: <Empty description="No shipping containers" /> }}
          pagination={false}
          rowKey="id"
          tableLayout="auto"
        />
      </Modal>
    </>
  )
}

export default DraftCounter
